/** @format */

.header {
  display: flex;
  align-items: stretch;
  padding: 15px;
  height: 150px;
  background-image: url('background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

.title {
  flex: 1;
  display: flex;
  text-align: left;
}

.title img {
  padding: 5px 20px 5px 5px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 15px;
}

.navigation {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 15px;
}
