/** @format */

.page {
  margin: 15px;
}

.page h1 {
  font-style: italic;
  text-decoration: underline;
}
