/** @format */

.button {
  margin: 15px 0;
}

.button a {
  border: 1px solid transparent;
  padding: 5px 2vw;
  margin: 0px 1vw;
  text-decoration: none;
  color: rgb(85, 69, 69);
  font-weight: bold;
  font-size: large;
  border-radius: 15px;
}

.button a:hover {
  border: 1px solid gray;
  background-color: wheat;
  color: black;
  text-shadow: none;
}
